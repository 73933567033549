<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_referral_stats')}}</b-breadcrumb-item>
            </div>
        </div>



        <b-card
                no-body
                class="mb-0"
        >
            <b-card-header class="pb-50">
                <h5>{{ $t('label_referral_stats') }}</h5>
            </b-card-header>

            <!--***********************************************************TABLE*********************************************-->

            <div class="px-2 table-container-wrap">
                <!--sticky-header-->
                <b-table
                        ref="table"
                        class="position-relative transited-table"
                        :busy="isBusy"
                        no-border-collapse
                        :items="tableItems"
                        responsive
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"

                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <!--<template #cell(display_name)="data">-->

                    <!--<a v-if="data.item.display_name" target="_blank" :href="$base_url+'mail/downloadMailDoc/' + data.item.id"> <feather-icon icon="fileIcon"></feather-icon> </a>-->

                    <!--</template>-->

                    <!--<template #cell(id)="data">-->
                        <!--{{'#' + data.item.id}}-->
                    <!--</template>-->
                    <!--<template #cell(deal_number)="data">-->
                        <!--{{data.item.deal.number}}-->
                    <!--</template>-->
                    <!--<template #cell(user_name)="data">-->
                        <!--{{data.item.user.name}}-->
                    <!--</template>-->
                    <!--<template #cell(document)="data">-->
                        <!--<a v-if="data.item.documents[0]" target="_blank"-->
                           <!--:href="$base_url+'mail/download/' + data.item.id + '?hash=' + data.item.documents[0].hash">-->
                            <!--<feather-icon icon="FileIcon"></feather-icon>-->
                        <!--</a>-->
                    <!--</template>-->




                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>


        </b-card>

        <!--<process-member-modal></process-member-modal>-->

    </div>
</template>

<script>
    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    // import processMemberModal from './../mail/modals/processMemberModal'

    import {MODULE_PREFIX, STATS_PREFIX as PREFIX} from './moduleHelper'

    export default {
        components: {
            BCard,

            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            vSelect,
            // processMemberModal,
        },

        // props: ['tableType'],

        data() {
            return {
                PREFIX,
                MODULE_PREFIX,
                editedItem: false,
                isSidebarOpen: false,
                table_name: 'table-contacts',
                // route_path: '',
                checkedIds: [],

                selectedColumns: [],

                columnsDefs: [

                    // {label: 'label_#', key: 'id', sortable: false},
                    // {
                    //     label: 'label_receiving_date', key: 'receiving_at', sortable: false,
                    //     formatter: function (v, k, item) {
                    //         return formatDate(v, 'DD.MM.YYYY', 'unix')
                    //     }
                    // },
                    {label: 'label_agent', key: 'name', sortable: false},
                    {label: 'label_link', key: 'link'},
                    {label: 'label_visits', key: 'visits'},
                    {label: 'label_registered_customers_count', key: 'customers_count'},

                ],

                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                // filterData: {
                //     sort_by_solicitor: '',
                //     sort_by_date_from: '',
                //     sort_by_date_to: '',
                //     sort_by_deal: ''
                // },
                // related_deals: [],
                // related_solicitors: [],

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            // filterData: {
            //     handler: function (old, newVal) {
            //         this.refreshDataTable();
            //     },
            //     deep: true
            // }
        },

        created() {

            // this.getPageData();

            // let allowed = this.columnsDefs.filter((el) => {
            //     if (el.key == 'actions' && !this.$can('edit', 'mail.tab_details')) {
            //         return false;
            //     }
            //     return true;
            // });

            // this.columnsDefs = allowed;
            this.refreshDataTable();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            // getPageData() {
            //     this.async('get', '/' + this.PREFIX + '/selects', {params: {mail_type: 'incoming'}}, function (res) {
            //         this.related_deals = res.data.relDeals;
            //         this.related_solicitors = res.data.relSolicitors;
            //     });
            // },
            //
            // changeDealStatus(module_id, status_alias) {
            //     this.async('post', '/' + this.PREFIX + '/change_status/' + module_id, {status_alias: status_alias}, function (resp) {
            //         this.refreshDataTable();
            //
            //         // console.log('status changed', resp);
            //
            //     });
            //
            // },

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/' + this.MODULE_PREFIX + '_' + this.PREFIX, {
                    params: {

                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc ? true : null,
                        // mail_type: 'incoming',

                        // sort_by_deal: this.filterData.sort_by_deal,
                        // sort_by_solicitor: this.filterData.sort_by_solicitor,
                        // sort_by_date_from: this.filterData.sort_by_date_from,
                        // sort_by_date_to: this.filterData.sort_by_date_to,

                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },

            // deleteItem(id) {
            //     this.confirmDeleting((result) => {
            //         this.async('delete', '/' + this.PREFIX + '/' + id, {}, function (resp) {
            //             this.refreshDataTable();
            //         });
            //     })
            // },
            // resolveRowClassVariant(item, type) {
            //     if (!item || type !== 'row') return
            //     if (this.checkedIds.includes(item.id)) return 'row-success';
            // },

        },


    }
</script>

